import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import components, { Layout } from '../components/index';
import { Constants } from '../utils';
import ThemeContext from '../context/ThemeContext';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class Advanced extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      isFollow: this.props.uri === '/follow'
    };
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Layout {...this.props}>
            {_.get(this.props, 'pageContext.frontmatter.share_title', null) && (
              <Helmet>
                <meta
                  property="og:title"
                  content={_.get(this.props, 'pageContext.frontmatter.share_title', null)}
                />
                <meta
                  property="og:description"
                  content={_.get(this.props, 'pageContext.frontmatter.share_description', null)}
                />
                <meta
                  property="og:image"
                  content={_.get(this.props, 'pageContext.frontmatter.share_image', null)}
                />
                <meta
                  property="og:url"
                  content={`${Constants.urlPrefix}${_.get(this.props, 'uri', null)}`}
                />
                <meta
                  name="twitter:title"
                  content={_.get(this.props, 'pageContext.frontmatter.share_title', null)}
                />
                <meta
                  name="twitter:description"
                  content={_.get(this.props, 'pageContext.frontmatter.share_description', null)}
                />
                <meta
                  name="twitter:image"
                  content={_.get(this.props, 'pageContext.frontmatter.share_image', null)}
                />
                <meta name="twitter:card" content="summary_large_image" />
              </Helmet>
            )}
            {_.map(
              _.get(this.props, 'pageContext.frontmatter.sections', null),
              (section, section_idx) => {
                let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
                let Component = components[component];
                return (
                  <Component
                    key={section_idx}
                    {...this.props}
                    section={section}
                    site={this.props.pageContext.site}
                    darkMode={theme.dark}
                  />
                );
              }
            )}
          </Layout>
        )}
      </ThemeContext.Consumer>
    );
  }
}
